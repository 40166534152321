<template>
  <header class="header position-fixed top-0 start-0 end-0 z-1">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <button
            class="btn p-0 text-white-50"
            @click="handleCollapse"
            v-if="props.route.path === '/'"
          >
            <i
              :class="auth.isCollapse ? 'bi bi-funnel' : 'bi bi-funnel-fill'"
            ></i>
          </button>

          <a href="/" v-else class="text-white-50">
            <i class="bi bi-house-door-fill"></i>
          </a>
        </div>
        <div class="col text-center">
          <a
            class="navbar-brand text-white text-uppercase fs-6 fw-bold"
            href="/"
            >asx-poc-web</a
          >
        </div>
        <div class="col text-end">
          <div class="d-flex align-items-center h-100 justify-content-end">
            <label class="switch me-2">
              <input
                type="checkbox"
                :checked="auth.theme === 'dark'"
                @click="handleTheme"
              />
              <span class="slider"></span>
            </label>

            <button class="btn text-white-50 p-0 ms-1" @click="handleLogout">
              <i class="bi bi-door-closed-fill"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  route: Object,
});
const store = useStore();
const auth = computed(() => {
  return store.state.auth;
});

function handleCollapse() {
  store.dispatch("auth/handleCollapse");
}

function handleLogout() {
  store.dispatch("auth/handleLogout");
}

function handleTheme() {
  store.dispatch(
    "auth/setTheme",
    auth.value.theme === "dark" ? "light" : "dark"
  );
}
</script>
