import moment from "moment-timezone";

export function formatNumber(value, decimal = 0) {
  return !isNaN(value)
    ? value
        .toFixed(decimal)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : 0;
}

export function formatDatetime(value) {
  return moment.tz(value, "Australia/Sydney").format("DD-MMM-YY hh:mm:ss A");
}

export function capitalizeFirstLetter(string) {
  if (string === "delta-hedge") {
    return "Deltahedge";
  } else if (string === "snapshot") {
    return "ASX Unhedged";
  } else {
    return string[0].toUpperCase() + string.slice(1);
  }
}

export function formatPagination(page, total) {
  let arr = Array.from({ length: total }, (_, i) => i + 1);
  if (total < 8) return arr;
  if (page < 3) {
    arr = arr
      .slice(0, 3)
      .concat(["..."], arr.slice(Math.max(arr.length - 3, 0)));
  } else if (page >= 3 && page < total - 6) {
    arr = arr
      .slice(page - 1, page + 2)
      .concat(["..."], arr.slice(Math.max(arr.length - 3, 0)));
  } else {
    arr = arr.slice(Math.max(arr.length - 7, 0));
  }
  return arr;
}

export function compareToday(dateTime) {
  var today = new Date().setHours(0, 0, 0, 0);
  var currentDay = new Date(
    moment.tz(dateTime, "Australia/Sydney").format("DD-MMM-YY hh:mm:ss A")
  ).getTime();
  if (currentDay >= today) return "table-primary";
  return null;
}
