<template>
  <div
    class="filter-header border-bottom border-color d-flex justify-content-between align-items-center px-3"
  >
    <div>
      <small><strong>Filters</strong></small>
    </div>
  </div>
  <div class="filter-body px-3 py-2 overflow-auto">
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">
        <small><strong>Region</strong></small>
      </label>

      <div
        class="form-check"
        v-for="(item, index) in data.regions"
        v-bind:key="index"
      >
        <input
          class="form-check-input border-color"
          type="radio"
          :id="item"
          name="region"
          :checked="item === data.selected.region"
          @change="onChangeRegion(item)"
          :disabled="data.spinner"
        />
        <label class="form-check-label" :for="item">
          <small>{{ item }}</small>
        </label>
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">
        <small><strong>Year & ASX Code</strong></small></label
      >
      <div
        class="accordion accordion-flush"
        id="accordionFlushExample"
        v-if="data.filters.length"
      >
        <div
          v-for="(item, index) in data.filters"
          class="accordion-item border-color"
          v-bind:key="index"
        >
          <button
            :class="
              item.year === data.selected.year
                ? 'accordion-button px-0 py-2'
                : 'accordion-button collapsed px-0 py-2'
            "
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#' + index"
            :aria-expanded="item.year === data.selected.year ? true : false"
          >
            <small>{{ item.year }}</small>
          </button>
          <div
            :id="index"
            :class="
              item.year === data.selected.year
                ? 'accordion-collapse collapse show'
                : 'accordion-collapse collapse'
            "
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body p-2">
              <div
                class="form-check"
                v-for="(subItem, subIndex) in item.asxCode"
                v-bind:key="subIndex"
              >
                <input
                  class="form-check-input border-color"
                  type="radio"
                  name="asxCode"
                  :id="subItem"
                  :checked="subItem === data.selected.asxCode"
                  @change="onChangeASXCode(subItem)"
                  :disabled="data.spinner"
                />
                <label class="form-check-label" :for="subItem">
                  <small>{{ subItem }} </small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const data = computed(() => {
  return store.state.data;
});

function onChangeRegion(value) {
  store.dispatch("data/onChangeRegion", value);
}

function onChangeASXCode(value) {
  data.value.selected.asxCode = value;
  store.dispatch("data/getData");
}
</script>
