<template>
  <div class="d-flex align-items-center vh-100 overflow-hidden">
    <div class="container-fluid">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

onMounted(() => {
  store.dispatch("auth/setTheme", "dark");
});
</script>
