<template>
  <template v-if="data.loading">
    <BaseLoading />
  </template>
  <template v-else>
    <BaseHeader :route="route" />
    <main class="main overflow-hidden position-relative">
      <div
        class="sidebar position-absolute top-0 start-0 bottom-0 border-end border-color"
      >
        <BaseFilter />
      </div>
      <div class="content">
        <slot />
      </div>
    </main>
  </template>
  <div class="modal show d-block" v-if="data.isError">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <h5 class="my-4">Something went wrong.<br />Please login again.</h5>
          <button class="btn btn-danger" @click="handleLogout">
            Go to login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseFilter from "@/components/BaseFilter.vue";
import { usePusher } from "@/composables/usePusher";

usePusher();
const route = useRoute();
const store = useStore();
const data = computed(() => {
  return store.state.data;
});

onMounted(() => {
  store.dispatch(
    "auth/setTheme",
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
  );
  if (route)
    store.dispatch(route.path !== "/all" ? "data/getFilter" : "data/getAll", 0);
});

function handleLogout() {
  store.dispatch("auth/handleLogout");
}
</script>
