import { axios } from "../axios";

export const dataTest = async (payload) => {
  try {
    const { data } = await axios.post(`/data`, payload);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const dataCurrent = async (asxCode) => {
  try {
    const { data } = await axios.get(`/data/current?asxCode=${asxCode}`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const dataAll = async (page, limit = 100) => {
  try {
    const { data } = await axios.get(`/data/all?page=${page}&limit=${limit}`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
