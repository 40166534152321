import moment from "moment-timezone";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  dataCurrent,
  asxYear,
  asxCode,
  asxRegion,
  dataTest,
  dataAll,
} from "@/services";
import {
  capitalizeFirstLetter,
  formatDatetime,
  formatNumber,
  formatPagination,
} from "@/helpers";

export default {
  namespaced: true,
  state: {
    loading: true,
    spinner: false,
    filters: [],
    selected: {
      region: "NSW",
      year: 2024,
      asxCode: "BNZ2024",
    },
    regions: [],
    trades: [],
    dateTime: "",
    pageCount: [],
    allTrade: [],
    pagination: {
      page: 0,
      total: 0,
      count: 0,
      limit: 100,
    },
    listToast: [],
    isError: false,
  },
  getters: {
    trades: (state) => state.trades,
  },
  actions: {
    async getData({ commit, state }) {
      try {
        commit("SET_SPINNER", true);
        const response = await dataCurrent(state.selected.asxCode);
        if (response.code === 0) {
          response.data.trades.sort(
            (a, b) =>
              new Date(b.settlementAt).getTime() -
              new Date(a.settlementAt).getTime()
          );

          commit("SET_TRADES", response.data.trades);
          commit("SET_DATE_TIME", moment().format("DD-MMM-YY hh:mm:ss A"));
          commit("SET_SPINNER", false);
        }
      } catch (error) {
        window.location.href = "/auth/login";
      }
    },

    async getDataInterval({ commit, state }) {
      try {
        const response = await dataCurrent(state.selected.asxCode);
        if (response.code === 0) {
          response.data.trades.sort(
            (a, b) =>
              new Date(b.settlementAt).getTime() -
              new Date(a.settlementAt).getTime()
          );

          commit("SET_TRADES", response.data.trades);
          commit("SET_DATE_TIME", moment().format("DD-MMM-YY hh:mm:ss A"));
        }
      } catch (error) {
        window.location.href = "/auth/login";
      }
    },

    async getFilter({ commit, state }) {
      try {
        const response = await asxRegion();
        if (response.code === 0) {
          commit("SET_REGIONS", response.data.regions);

          try {
            const response = await asxYear(state.selected.region);
            if (response.code === 0) {
              let filters = [];

              for (let index = 0; index < response.data.years.length; index++) {
                const element = response.data.years[index];

                try {
                  const response = await asxCode(
                    state.selected.region,
                    element
                  );
                  if (response.code === 0) {
                    filters.push({
                      year: element,
                      asxCode: response.data.asxCodes,
                    });
                  }
                } catch (error) {
                  console.log(error);
                }
              }

              commit("SET_FILTERS", filters);
              commit("SET_LOADING", false);
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        commit("SET_IS_ERROR", true);
      }
    },

    async onChangeRegion({ commit, dispatch }, value) {
      try {
        commit("SET_SPINNER", true);
        commit("SET_FILTERS", []);
        const response = await asxYear(value);
        if (response.code === 0) {
          let filters = [];

          for (let index = 0; index < response.data.years.length; index++) {
            const element = response.data.years[index];

            try {
              const response = await asxCode(value, element);
              if (response.code === 0) {
                filters.push({
                  year: element,
                  asxCode: response.data.asxCodes,
                });
              }
            } catch (error) {
              console.log(error);
            }
          }
          commit("SET_SELECTED", {
            region: value,
            year: filters[0].year,
            asxCode: filters[0].asxCode[0],
          });
          commit("SET_FILTERS", filters);
          commit("SET_SPINNER", false);
          dispatch("getData");
        }
      } catch (error) {
        commit("SET_IS_ERROR", true);
      }
    },

    async onTest() {
      try {
        let items = [
          { region: "QLD", year: 2025, asxCode: "BQH2025" },
          { region: "SA", year: 2024, asxCode: "BSZ2024" },
          { region: "NSW", year: 2024, asxCode: "BNZ2024" },
          { region: "NSW", year: 2022, asxCode: "BNU2022" },
        ];
        let random = Math.floor(Math.random() * items.length);

        await dataTest({
          settlementAt: "2025-01-15T00:39:10.000Z",
          futurePrice: 99.5,
          futureSource: "snapshot",
          optionPrice: 9.9,
          optionType: "put",
          period: "Q4",
          strikePrice: 95,
          volatility: 62.289691085275976,
          volume: 25,
          movingAvg: 62.289691085275976,
          region: items[random].region,
          year: items[random].year,
          asxCode: items[random].asxCode,
          delta: Math.random() * (60 - 1) + 1,
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getAll({ commit, state }, page) {
      try {
        const response = await dataAll(page);
        if (response.code === 0) {
          commit("SET_ALL_TRADE", response.data.data);
          commit("SET_PAGINATION", response.data.pagination);
          commit("SET_DATE_TIME", moment().format("DD-MMM-YY hh:mm:ss A"));
          commit(
            "SET_PAGE_COUNT",
            formatPagination(state.pagination.page, state.pagination.total)
          );
          commit("SET_LOADING", false);
        }
      } catch (error) {
        commit("SET_IS_ERROR", true);
      }
    },

    async handleDownload({ state }) {
      const wb = new ExcelJS.Workbook();
      const worksheet = wb.addWorksheet();
      worksheet.columns = [
        { header: "Datetime", key: "settlementAt", width: 10 },
        { header: "Region", key: "region", width: 32 },
        { header: "ASXCode", key: "asxCode", width: 10 },
        { header: "Term", key: "period", width: 10 },
        { header: "YearCode", key: "year", width: 10 },
        { header: "Category", key: "futureSource", width: 10 },
        { header: "Volatility", key: "volatility", width: 10 },
        { header: "Delta", key: "delta", width: 10 },
        { header: "Optionality", key: "optionType", width: 10 },
        { header: "StrikePrice", key: "strikePrice", width: 10 },
        { header: "FuturePrice", key: "futurePrice", width: 10 },
        { header: "OptionPrice", key: "optionPrice", width: 10 },
        { header: "Volume", key: "volume", width: 10 },
      ];
      for (let index = 0; index < state.trades.length; index++) {
        const element = state.trades[index];
        worksheet.addRow({
          settlementAt: formatDatetime(element.settlementAt),
          region: element.region,
          asxCode: element.asxCode,
          period: element.period,
          year: element.year,
          futureSource: capitalizeFirstLetter(element.futureSource),
          volatility: formatNumber(element.volatility, 3),
          delta: element.delta ? formatNumber(element.delta, 3) : "N/A",
          optionType: capitalizeFirstLetter(element.optionType),
          strikePrice: formatNumber(element.strikePrice),
          futurePrice: formatNumber(element.futurePrice, 2),
          optionPrice: formatNumber(element.optionPrice, 2),
          volume: formatNumber(element.volume),
        });
      }
      var buf = await wb.csv.writeBuffer();
      saveAs(
        new Blob([buf]),
        `${state.selected.asxCode}-${moment().format(
          "DD-MMM-YY hh:mm:ss A"
        )}.csv`
      );
    },

    async onClickToast({ commit, dispatch }, value) {
      try {
        commit("SET_SELECTED", {
          region: value.region,
          year: value.year,
          asxCode: value.asxCode,
        });
        dispatch("onDeleteCode", value.asxCode);
        commit("SET_SPINNER", true);
        commit("SET_FILTERS", []);
        const response = await asxYear(value.region);
        if (response.code === 0) {
          let filters = [];
          for (let index = 0; index < response.data.years.length; index++) {
            const element = response.data.years[index];
            try {
              const response = await asxCode(value.region, element);
              if (response.code === 0) {
                filters.push({
                  year: element,
                  asxCode: response.data.asxCodes,
                });
              }
            } catch (error) {
              console.log(error);
            }
          }
          commit("SET_FILTERS", filters);
          commit("SET_SPINNER", false);
          dispatch("getData");
        }
      } catch (error) {
        commit("SET_IS_ERROR", true);
      }
    },

    onDeleteCode({ commit, state }, value) {
      let temp = state.listToast.filter((item) => item.asxCode !== value);
      commit("SET_LIST_TOAST", temp);
    },
  },

  mutations: {
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_SPINNER(state, data) {
      state.spinner = data;
    },
    SET_REGIONS(state, data) {
      state.regions = data;
    },
    SET_ASX_CODE(state, data) {
      state.asxCode = data;
    },
    SET_FILTERS(state, data) {
      state.filters = data;
    },
    SET_SELECTED(state, data) {
      Object.entries(data).map(([key, value]) => {
        state.selected[key] = value;
      });
    },
    SET_TRADES(state, data) {
      state.trades = data;
    },
    SET_DATE_TIME(state, data) {
      state.dateTime = data;
    },
    SET_ALL_TRADE(state, data) {
      state.allTrade = data;
    },
    SET_PAGINATION(state, data) {
      Object.entries(data).map(([key, value]) => {
        state.pagination[key] = value;
      });
    },
    SET_PAGE_COUNT(state, data) {
      state.pageCount = data;
    },
    SET_LIST_TOAST(state, data) {
      state.listToast = data;
    },
    SET_IS_ERROR(state, data) {
      state.isError = data;
    },
  },
};
