import { createRouter, createWebHistory } from "vue-router";
import checkAuth from "@/middleware/checkAuth";
import checkGuest from "@/middleware/checkGuest";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

const routes = [
  {
    path: "/",
    meta: {
      layout: DefaultLayout,
      middleware: checkAuth,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        alias: "dashboard",
        component: () => import("@/views/HomeView.vue"),
        meta: {
          breadCrumbs: [{ name: "Dashboard" }],
        },
      },
      {
        path: "/all",
        name: "All",
        alias: "all",
        component: () => import("@/views/AllView.vue"),
        meta: {
          breadCrumbs: [{ name: "All" }],
        },
      },
    ],
  },
  {
    path: "/auth",
    meta: {
      layout: AuthLayout,
      middleware: checkGuest,
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/LoginView.vue"),
      },
      {
        path: "callback",
        name: "Callback",
        component: () => import("@/views/CallbackView.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Not Found",
    component: () => import("@/views/NotFound.vue"),
  },
];
const router = new createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //document.title = to.name;
  const middleware = to.meta.middleware;
  const context = { to, from, next };

  if (!middleware) {
    return next();
  }

  middleware({ ...context });
});

export default router;
